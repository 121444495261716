import React, { useContext, useEffect, useState } from "react";
import QRCode from "react-qr-code";
import TitleSection from "../../components/shared/TitleSection/titleSection";
import { getCurrentAuthenticatedUser } from "../../utils/utils";
import { IUser } from "../../types/user";
import Swal from "sweetalert2";
import { userInfo } from "os";
import AppContext from "../../context/appContext";
interface DashboardScreenProps {
  updateUserInfo: (info: any) => void;
}

const DashboardScreen = ({ updateUserInfo }: DashboardScreenProps) => {
  const [isActive, setIsActive] = useState(true);
  const [currentUser, setCurrentUser] = useState<Partial<IUser>>({});
  const [currentJwtToken, setCurrentJwtToken] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { userInfo } = useContext(AppContext);

  const setError = (errorMessage: string) => {
    console.log("errorMessage", errorMessage);
    Swal.fire("Error", `${errorMessage}`, "error");
  };

  const handleUserInfo = (info: any) => {
    updateUserInfo(info);
  };

  const gerNickName = () => {
    const { nickname } = currentUser;
    if (nickname) {
      return nickname;
    }
    const name = currentUser.name?.split(" ");
    if (name?.length) {
      return name[0];
    }
    return currentUser.name;
  };

  const getCurrentUser = async () => {
    try {
      setIsLoading(true);
      const result = await getCurrentAuthenticatedUser();
      if (result) {
        const { somaUser, jwtToken } = result;
        if (somaUser) {
          setCurrentJwtToken(jwtToken);
          setCurrentUser(somaUser);
          const isActive = somaUser.activeMembership || false;
          setIsActive(isActive);
        }
      }
      setIsLoading(false);
    } catch (error) {
      setError(error as any);
    }
  };

  useEffect(() => {
    if (!Object.keys(currentUser).length) {
      getCurrentUser();
    }
  }, []);

  return (
    <>
      <section className="dashboard-layout">
        <section className="module">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-7 col-lg-5  mx-auto">
                <TitleSection text={"Membresía"} />
                <h3 className="mb-4">Hi {`${gerNickName() || ""}`}!</h3>
                <div className="membership-block">
                  <figure className="qr-code">
                    <QRCode
                      value={JSON.stringify({ userId: currentUser._id })}
                      size={178}
                    />
                  </figure>
                  <section className="membership-info">
                    <div className="membership-info__item">
                      <p>
                        <small>Member Id</small>
                      </p>
                      <p className="h3">{`${currentUser.memberId || ""}`}</p>
                    </div>
                    <div className="membership-info__item">
                      <p>
                        <small>Member</small>
                      </p>
                      <p className="h3">{`${currentUser.name} ${currentUser?.lastName}`}</p>
                    </div>
                    <div
                      className={`membership__status ${
                        isActive ? "active" : "inactive"
                      }`}
                    >
                      <i></i>
                      <p>
                        <small>{isActive ? "Activo" : "Inactivo"}</small>
                      </p>
                    </div>
                  </section>
                </div>
                <div className="membership-actions">
                  <div className="membership-actions__item">
                    <figure className="logo-label">
                      <a
                        target="__blank"
                        href="https://zxing.org/w/chart?cht=qr&chs=350x350&chld=L&choe=UTF-8&chl=MECARD%3AN%3ARafael+Roig%3BORG%3ASOMA+%2B%3BTEL%3A5533343392%3BURL%3Asoma.group%3BEMAIL%3Arroig%40sma.com.mx%3BADR%3ACorporativo+Antara+II+Piso+3+Av.+Ej%C3%A9rcito+Nacional+843-B+Col.+Granada+C.P.+11520+Ciudad+de+M%C3%A9xico%3BNOTE%3ASOMA+%2B+Membership%3B%3B"
                      >
                        <img
                          src="/assets/images/label/wallet.png"
                          alt="Wallet"
                        />
                      </a>
                    </figure>
                    <a href="mailto:info@soma.group" className="btn btn--type1">
                      Enviar por correo
                    </a>
                  </div>
                  <div className="membership-actions__item">
                    <p>Para una mejor experiencia, descarga la aplicación</p>
                  </div>
                  <div className="membership-actions__item">
                    <figure className="logo-label">
                      <img
                        src="/assets/images/label/apple.png"
                        alt="AppleStore"
                      />
                    </figure>
                    <figure className="logo-label">
                      <img
                        src="/assets/images/label/playstore.png"
                        alt="PlayStore"
                      />
                    </figure>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    </>
  );
};

export default DashboardScreen;
