import {
  fetchAuthSession,
  fetchUserAttributes,
  updateUserAttributes,
} from "aws-amplify/auth";
import {
  getUser,
  getUserInvitation,
  searchUser,
  userSignUp,
  validateUserInvitation,
} from "../api/usersApi";
import { deleteSession, saveSession } from "./helpers";
import { searchProfileFavorites } from "../api/userBrandsFavorites";
import { IUser } from "../types/user";
import Swal from "sweetalert2";

export const getCurrentAuthUserAttributes = async () => {
  try {
    const authSession = await fetchAuthSession();
    const userAttributes = await fetchUserAttributes();
    console.log("getCurrentAuthUserAttributes", {
      authSession,
      userAttributes,
    });

    if (authSession && userAttributes) {
      const key = "custom:somaPlusUserId";
      const somaPlusUserId = userAttributes[key] || "";
      const jwtToken = authSession.tokens?.accessToken.toString() || "";
      const currentUser = JSON.parse(localStorage.getItem("userInfo") || "");

      if (somaPlusUserId) {
        const somaUser = await getUser(somaPlusUserId, jwtToken);
        localStorage.setItem(
          "userInfo",
          JSON.stringify({
            ...(typeof currentUser !== "string" && currentUser),
            ...somaUser,
            token: jwtToken,
          })
        );
        return { somaUser, jwtToken };
      }
    }
  } catch (error) {
    return null;
  }
  return null;
};

export const getCurrentAuthenticatedUser = async () => {
  try {
    const authSession = await fetchAuthSession();
    const userAttributes = await fetchUserAttributes();

    if (authSession && userAttributes) {
      const key = "custom:somaPlusUserId";
      const somaPlusUserId = userAttributes[key] || "";
      const jwtToken = authSession.tokens?.accessToken.toString() || "";

      if (somaPlusUserId) {
        const somaUser = await getUser(somaPlusUserId, jwtToken);
        await saveSession(somaUser);
        if (window.location.pathname === "/") {
          window.location.href = "/events";
        }
        return { somaUser, jwtToken };
      } else {
        const email = userAttributes.email || "";
        if (email) {
          const searchResult = await searchUser(0, 1, "", { email }, jwtToken);
          if (searchResult && searchResult.docs.length) {
            const currentSomaPlusUser = searchResult.docs[0];
            console.log("searchResult", currentSomaPlusUser);
            await updateUserAttributes({
              userAttributes: {
                "custom:somaPlusUserId": currentSomaPlusUser._id,
              },
            });
            await saveSession(currentSomaPlusUser);
            return { somaUser: currentSomaPlusUser, jwtToken };
          }
        }
        const somaPlusUser = await userSignUp({ email, terms: true }, jwtToken);
        const newUserAttributes: any = {
          "custom:somaPlusUserId": somaPlusUser._id || "",
        };
        ["intercomId", "stripeCustomerId"].forEach((key) => {
          if (somaPlusUser[key as keyof typeof somaPlusUser]) {
            newUserAttributes[`custom:${key}`] =
              somaPlusUser[key as keyof typeof somaPlusUser];
          }
        });
        console.log("newUserAttributes", { userAttributes, newUserAttributes });
        await updateUserAttributes({ userAttributes: newUserAttributes });
        await saveSession(somaPlusUser);
        return { somaUser: somaPlusUser, jwtToken };
      }
    }
  } catch (error) {
    return null;
  }
};

const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const socialAuthEvent = async (
  evenData: any,
  setIsLoading: (value: React.SetStateAction<boolean>) => void,
  setError: (errorMessage: string) => void,
  navigate: any
) => {
  const {
    payload: { event },
  } = evenData;

  switch (event) {
    case "signInWithRedirect":
      const authSession = await fetchAuthSession();
      const userAttributes = await fetchUserAttributes();

      if (authSession && userAttributes) {
        const email = userAttributes.email || "";
        const jwtToken = authSession.tokens?.accessToken.toString() || "";
        try {
          await sleep(500);
          const searchResult = await searchUser(0, 1, "", { email }, jwtToken);
          await saveSession(searchResult.docs[0]);
          if (searchResult && searchResult.docs.length) {
            const currentSomaPlusUser = searchResult.docs[0];
            const { isDelete } = currentSomaPlusUser;

            await updateUserAttributes({
              userAttributes: {
                "custom:somaPlusUserId": currentSomaPlusUser._id,
                "custom:intercomId": currentSomaPlusUser.intercomId || "",
                "custom:stripeCustomerId":
                  currentSomaPlusUser.stripeCustomerId || "",
              },
            });
            if (!currentSomaPlusUser.stripeCustomerId && !isDelete) {
              navigate("/signup-complete", {
                state: {
                  user: currentSomaPlusUser,
                  socialLogin: true,
                },
              });
            } else {
              const somaUser = await getUser(
                currentSomaPlusUser._id || "",
                jwtToken
              );
              const isActive = somaUser.activeMembership || false;
              if (isDelete && !isActive) {
                console.log("Deleted account");
                // return navigation.navigate('DeletedAccount', {});
              } else {
                // Si no tiene marcas favoritas
                const brands = await searchProfileFavorites(
                  currentSomaPlusUser._id || "",
                  jwtToken
                );
                if (brands.length === 0 || brands[0].brands.length === 0) {
                  navigate("/favorites-experience");
                } else {
                  // Si la membresía está inactiva, redirige al usuario.
                  if (!isActive) {
                    console.log("Inactive account");
                    // return navigation.navigate('DashboardInactive', {});
                  } else {
                    // Si no ha agregado al wallet
                    if (
                      !somaUser.passKitId ||
                      somaUser.passKitId === undefined ||
                      somaUser.passKitId === ""
                    ) {
                      navigate("/events");
                    } else {
                      //Membresía activa y wallet agregada
                      navigate("/events");
                    }
                  }
                }
              }
            }
            return {
              somaPlusUserId: currentSomaPlusUser._id,
              jwtToken,
            };
          } else {
            const somaPlusUser = await userSignUp(
              {
                email,
              },
              jwtToken
            );
            const userAttributes: any = {
              "custom:somaPlusUserId": somaPlusUser._id || "",
            };
            ["intercomId", "stripeCustomerId"].forEach((key) => {
              if (somaPlusUser[key as keyof typeof somaPlusUser]) {
                userAttributes[`custom:${key}`] =
                  somaPlusUser[key as keyof typeof somaPlusUser];
              }
            });
            await updateUserAttributes({ userAttributes });
            navigate("/signup-complete", {
              state: {
                user: somaPlusUser,
                socialLogin: true,
              },
            });
            return {
              somaPlusUserId: somaPlusUser._id,
              jwtToken,
            };
          }
        } catch (error) {
          console.log("error", error);
          setIsLoading(false);
          setError("Unknown error, please try again");
        }
      }
      setIsLoading(false);
      break;
    case "signedOut":
      setIsLoading(false);
      deleteSession();
      // Pendiente revisar por que se detona el evento "signedOut"
      // navigate("/", {});
      break;
    case "signIn_failure":
      setIsLoading(false);
      setError("Unknown error, please try again");
      break;
    case "cognitoHostedUI_failure":
      setIsLoading(false);
      setError("Unknown error, please try again");
      break;
    default:
      return null;
  }
  return null;
};

export const checkAndAskForInviteCode = async (
  somaUser: IUser,
  jwtToken: string
) => {
  const { _id: userId } = somaUser;
  if (!userId) {
    return;
  }
  const userInvitation = await getUserInvitation(jwtToken, userId);
  const { docs } = userInvitation;
  if (docs && docs.length) {
    return;
  }
  Swal.fire({
    title: "Código de invitación",
    input: "text",
    inputPlaceholder: "Intrduce tu código de invitación",
    showCancelButton: false,
    confirmButtonText: "Submit",
    showLoaderOnConfirm: true,
    allowEscapeKey: false,
    allowOutsideClick: false,
    preConfirm: async (invCode) => {
      console.log("invCode", invCode);
      if (!invCode) {
        Swal.showValidationMessage("Se requiere un código de invitación");
        return;
      }
      try {
        await validateUserInvitation(invCode, userId);
        Swal.fire("Success!", "Tu código es válido", "success");
      } catch (error) {
        Swal.showValidationMessage(`Código de invitación inválido`);
      }
    },
  });
};
