import React, { useContext, useEffect, useState } from "react";
import QRCode from "react-qr-code";
import { IUser } from "../../types/user";
import Swal from "sweetalert2";
import { getCurrentAuthenticatedUser } from "../../utils/utils";
import SubHeaderComponent from "../../components/shared/Subheader/subHeader.component";
import { signOut } from "aws-amplify/auth";
import { deleteSession } from "../../utils/helpers";
import AppContext from "../../context/appContext";

const InactiveMembershipScreen = () => {
  const { handleUserInfo } = useContext(AppContext);
  const [cancelMembership, setCancelMembership] = useState(false);
  const [membershipCanceled, setMembershipCanceled] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const [currentUser, setCurrentUser] = useState<Partial<IUser>>({});

  const setError = (errorMessage: string) => {
    console.log("errorMessage", errorMessage);
    Swal.fire("Error", `${errorMessage}`, "error");
  };
  const userInfo = JSON.parse(localStorage.getItem("userInfo") || "");

  const whatsapp = `whatsapp://send?phone=+525566097673&text=Hola, soy ${
    currentUser?.name + " " + currentUser?.lastName || ""
  } - (${currentUser?._id || ""}) quiero hablar con mi Concierge.`;

  const getCurrentUser = async () => {
    try {
      const result = await getCurrentAuthenticatedUser();
      if (result) {
        const { somaUser, jwtToken } = result;
        if (somaUser) {
          setCurrentUser({ ...somaUser, token: jwtToken });
          const isActive = somaUser.activeMembership || false;
          setIsActive(isActive);
        }
      }
    } catch (error) {
      setError(error as any);
    }
  };

  const handleLogout = async () => {
    signOut();
    deleteSession();
    handleUserInfo(null);
    localStorage.removeItem("userInfo");
    window.location.href = "/login";
  };

  useEffect(() => {
    if (Object.keys(userInfo).length) {
      setIsActive(userInfo?.activeMembership);
    }
    if (!Object.keys(currentUser).length) {
      getCurrentUser();
    }
  }, []);

  return (
    <>
      <section className="dashboard-layout">
        <section className="module">
          <div className="container">
            <div className="row">
              <div className="col-lg-5 col-xl-4 col-md-7 col-12 mx-auto">
                <figure className="text-center mb-5">
                  <img
                    src="/assets/images/logo/soma-white.svg"
                    alt="SOMA + Membership"
                  />
                </figure>

                <div className="col-12 text-left mb-3">
                  <h3>
                    Hola, {currentUser?.name || userInfo?.name}{" "}
                    {currentUser?.lastName || userInfo?.lastName}!
                  </h3>
                </div>

                <div className="membership-block inactive">
                  <section className="membership-info">
                    <div className="membership-info__item">
                      <div className="membership-message">
                        <p>
                          <b>Tu membresía SOMA+ está inactiva.</b>
                        </p>
                        <p>
                          Para continuar disfrutando de los beneficios de SOMA+,
                          realiza el pago necesario o comunícate con Concierge
                          para obtener ayuda.
                        </p>
                      </div>
                    </div>
                    <div className="membership-info__item">
                      <p>
                        <small>Member Id</small>
                      </p>
                      <p className="h3">{`${
                        currentUser.memberId || userInfo?.memberId
                      }`}</p>
                    </div>
                    <div className="membership-info__item">
                      <p>
                        <small>Miembro</small>
                      </p>
                      <p className="h3">{`${
                        currentUser?.name || userInfo?.name
                      } ${currentUser?.lastName || userInfo?.lastName}`}</p>
                    </div>
                  </section>
                </div>

                <div className="membership-actions">
                  {/* <div className="membership-actions__item">
                    <button className="btn btn--type1 w-100">
                      Pagar membresía
                    </button>
                  </div> */}

                  <div className="membership-actions__item text-center">
                    <a
                      className="text-center mx-auto text-decoration-none"
                      href={whatsapp}
                    >
                      Hablar con Concierge
                    </a>
                  </div>

                  <div className="membership-actions__item">
                    <button
                      className="btn btn--type1 w-100"
                      onClick={handleLogout}
                    >
                      Cerrar sesión
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    </>
  );
};

export default InactiveMembershipScreen;
