export const category: any = {
  // Brands
  brands: {
    id: "Brands",
    name: "Brands",
    action: {
      label: "Agendar un personal shopper",
      screen: "/Brands/request/Brands",
      params: {},
    },
    subcategories: [
      {
        id: "Luxury",
        name: "Luxury",
        icon: "/assets/images/icons/brands-tab/luxury.png",
        action: {
          screen: "/Brands/Brands/Luxury",
          params: {
            previousScreen: "Brands",
            subcategory: {
              id: "Luxury",
              name: "Luxury",
              icon: "/assets/images/icons/brands-tab/luxury.png",
              action: {
                screen: "DetailShopping",
              },
            },
          },
        },
      },
      {
        id: "Women",
        name: "Women",
        icon: "/assets/images/icons/brands-tab/women.png",
        action: {
          screen: "/Brands/Brands/Women",
          params: {
            previousScreen: "Brands",
            subcategory: {
              id: "Women",
              name: "Women",
              icon: "/assets/images/icons/brands-tab/women.png",
              action: {
                screen: "DetailShopping",
              },
            },
          },
        },
      },
      {
        id: "Men",
        name: "Men",
        icon: "/assets/images/icons/brands-tab/men.png",
        action: {
          screen: "/Brands/Brands/Men",
          params: {
            previousScreen: "Brands",
            subcategory: {
              id: "Men",
              name: "Men",
              icon: "/assets/images/icons/brands-tab/men.png",
              action: {
                screen: "DetailShopping",
              },
            },
          },
        },
      },
      {
        id: "Kids",
        name: "Kids",
        icon: "/assets/images/icons/brands-tab/kids.png",
        action: {
          screen: "/Brands/Brands/Kids",
          params: {
            previousScreen: "Brands",
            subcategory: {
              id: "Kids",
              name: "Kids",
              icon: "/assets/images/icons/brands-tab/kids.png",
              action: {
                screen: "DetailShopping",
              },
            },
          },
        },
      },
      {
        id: "Jewelry",
        name: "Jewelry",
        icon: "/assets/images/icons/brands-tab/jewelry.png",
        action: {
          screen: "/Brands/Brands/Jewelry",
          params: {
            previousScreen: "Brands",
            subcategory: {
              id: "Jewelry",
              name: "Jewelry",
              icon: "/assets/images/icons/brands-tab/jewelry.png",
              action: {
                screen: "DetailShopping",
              },
            },
          },
        },
      },
      {
        id: "Beauty",
        name: "Beauty",
        icon: "/assets/images/icons/brands-tab/beauty.png",
        action: {
          screen: "/Brands/Brands/Beauty",
          params: {
            previousScreen: "Brands",
            subcategory: {
              id: "Beauty",
              name: "Beauty",
              icon: "/assets/images/icons/brands-tab/beauty.png",
              action: {
                screen: "DetailShopping",
              },
            },
          },
        },
      },
      {
        id: "Home Decor",
        name: "Home Decor",
        icon: "/assets/images/icons/brands-tab/home.png",
        action: {
          screen: "/Brands/Brands/Home Decor",
          params: {
            previousScreen: "Brands",
            subcategory: {
              id: "Home Decor",
              name: "Home Decor",
              icon: "/assets/images/icons/brands-tab/home.png",
              action: {
                screen: "DetailShopping",
              },
            },
          },
        },
      },
      {
        id: "Tech",
        name: "Tech",
        icon: "/assets/images/icons/brands-tab/tech.png",
        action: {
          screen: "/Brands/Brands/Tech",
          params: {
            previousScreen: "Brands",
            subcategory: {
              id: "Tech",
              name: "Tech",
              icon: "/assets/images/icons/brands-tab/tech.png",
              action: {
                screen: "DetailShopping",
              },
            },
          },
        },
      },
      {
        id: "Sports",
        name: "Sports",
        icon: "/assets/images/icons/brands-tab/sports.png",
        action: {
          screen: "/Brands/Brands/Sports",
          params: {
            previousScreen: "Brands",
            subcategory: {
              id: "Sports",
              name: "Sports",
              icon: "/assets/images/icons/brands-tab/sports.png",
              action: {
                screen: "DetailShopping",
              },
            },
          },
        },
      },
      {
        id: "Footwear",
        name: "Footwear",
        icon: "/assets/images/icons/brands-tab/footwear.png",
        action: {
          screen: "/Brands/Brands/Footwear",
          params: {
            previousScreen: "Brands",
            subcategory: {
              id: "Footwear",
              name: "Footwear",
              icon: "/assets/images/icons/brands-tab/footwear.png",
              action: {
                screen: "DetailShopping",
              },
            },
          },
        },
      },
      {
        id: "Accessories",
        name: "Accessories",
        icon: "/assets/images/icons/brands-tab/accesories.png",
        action: {
          screen: "/Brands/Brands/Accessories",
          params: {
            previousScreen: "Brands",
            subcategory: {
              id: "Accessories",
              name: "Accessories",
              icon: "/assets/images/icons/brands-tab/accesories.png",
              action: {
                screen: "DetailShopping",
              },
            },
          },
        },
      },
    ],
  },
  // Studios
  studios: {
    id: "Studios",
    name: "Studios",
    subcategories: [
      {
        id: "Commando",
        name: "Commando",
        icon: "/assets/images/icons/studios-tab/commando.png",
        action: {
          detail: true,
          screen: "/Studios/detail/Brands/11",
          params: {
            previousScreen: "Studios",
            idPromo: 11,
          },
        },
      },
      {
        id: "Sersana",
        name: "Sersana",
        icon: "/assets/images/icons/studios-tab/sersana.png",
        action: {
          detail: true,
          screen: "/Studios/detail/Brands/13",
          params: {
            previousScreen: "Studios",
            idPromo: 13,
          },
        },
      },
      {
        id: "Siclo",
        name: "Siclo",
        icon: "/assets/images/icons/studios-tab/siclo.png",
        action: {
          detail: true,
          screen: "/Studios/detail/Brands/14",
          params: {
            previousScreen: "Siclo",
            idPromo: 14,
          },
        },
      },
      {
        id: "Zuda",
        name: "Zuda",
        icon: "/assets/images/icons/studios-tab/sersana.png",
        action: {
          detail: true,
          screen: "/Studios/detail/Brands/141",
          params: {
            previousScreen: "Zuda",
            idPromo: 141,
          },
        },
      },
      // {
      //   id: 'UFC',
      //   name: 'UFC',
      //   icon: '/assets/images/icons/studios-tab/ufc.png',
      //   action: {
      //     detail: true,
      //     screen: '/detail/Brands/38',
      //     params: {
      //       previousScreen: 'UFC',
      //       idPromo: 38,
      //     }
      //   }
      // },
      {
        id: "Flashdance",
        name: "Flashdance",
        icon: "/assets/images/icons/studios-tab/flashdance.png",
        action: {
          detail: true,
          screen: "/Studios/detail/Brands/12",
          params: {
            previousScreen: "Flashdance",
            idPromo: 12,
          },
        },
      },
      {
        id: "The Face Method",
        name: "The Face Method",
        icon: "/assets/images/icons/studios-tab/the-face-method.png",
        action: {
          detail: true,
          screen: "/Studios/detail/Brands/34",
          params: {
            previousScreen: "The Face Method",
            idPromo: 34,
          },
        },
      },
      {
        id: "SKINNY LAB",
        name: "SKINNY LAB",
        icon: "/assets/images/icons/studios-tab/skinny-lab.png",
        action: {
          detail: true,
          screen: "/Studios/detail/Brands/35",
          params: {
            previousScreen: "SKINNY LAB",
            idPromo: 35,
          },
        },
      },
      {
        id: "TAN ME",
        name: "TAN ME",
        icon: "/assets/images/icons/studios-tab/tan-me.png",
        action: {
          detail: true,
          screen: "/Studios/detail/Brands/71",
          params: {
            previousScreen: "TAN ME",
            idPromo: 71,
          },
        },
      },
      // {
      //   id: 'Aveda',
      //   name: 'Aveda',
      //   icon: '/assets/images/icons/studios-tab/aveda.png',
      //   action: {
      //     detail: true,
      //     screen: '/detail/Brands/54',
      //     params: {
      //       previousScreen: 'Aveda',
      //       idPromo: 54,
      //     }
      //   }
      // },
    ],
  },
  // Entertainment
  entertainment: {
    id: "Entertainment",
    name: "Entertainment",
    subcategories: [
      {
        id: "Cinemex",
        name: "Cinemex",
        icon: "/assets/images/icons/entertainment-tab/cinemex.png",
        action: {
          screen: "/Entertainment/cinemex",
          params: {
            previousScreen: "EntertainmentIndex",
          },
        },
      },
      // {
      //   id: 'Alboa',
      //   name: 'Alboa',
      //   icon: '/assets/images/icons/entertainment-tab/alboa.png',
      //   action: {
      //     screen: '/Entertainment/detail/Brands/9',
      //     params: {
      //       previousScreen: 'EntertainmentIndex',
      //       idPromo: 9,
      //     }
      //   }
      // },
      // {
      //   id: 'Arena',
      //   name: 'Arena',
      //   icon: '/assets/images/icons/entertainment-tab/arena.png',
      //   action: {
      //     screen: '/Entertainment/detail/Brands/30',
      //     params: {
      //       previousScreen: 'EntertainmentIndex',
      //       idPromo: 30,
      //     }
      //   }
      // },
      {
        id: "Funever Dreaming",
        name: "Funever Dreaming",
        icon: "/assets/images/icons/entertainment-tab/funever-dreaming.png",
        action: {
          screen: "/Entertainment/detail/Brands/37",
          params: {
            previousScreen: "EntertainmentIndex",
            idPromo: 37,
          },
        },
      },
      {
        id: "Arte Abierto",
        name: "Arte Abierto",
        icon: "/assets/images/icons/entertainment-tab/arte-abierto.png",
        action: {
          screen: "/Entertainment/detail/Brands/31",
          params: {
            previousScreen: "EntertainmentIndex",
            idPromo: 31,
          },
        },
      },
    ],
  },
  // Dining
  dining: {
    id: "Restaurants",
    name: "Dining",
    action: {
      label: "Reservar mesa",
      screen: "/Dining/request/Dining",
      params: { previousScreen: "Dining" },
    },
    subcategories: [
      {
        id: "Fine Dining",
        name: "Fine Dining",
        icon: "/assets/images/icons/dining-tabs/fine.png",
        action: {
          screen: "/Dining/Restaurants/Fine Dining",
          params: {
            previousScreen: "Dining",
            subcategory: {
              id: "Fine Dining",
              name: "Fine Dining",
              icon: "/assets/images/icons/dining-tabs/fine.png",
              action: {
                screen: "DetailDinning",
              },
            },
          },
        },
      },
      {
        id: "Casual Dining",
        name: "Casual Dining",
        icon: "/assets/images/icons/dining-tabs/casual.png",
        action: {
          screen: "/Dining/Restaurants/Casual Dining",
          params: {
            previousScreen: "Dining",
            subcategory: {
              id: "Casual Dining",
              name: "Casual Dining",
              icon: "/assets/images/icons/dining-tabs/casual.png",
              action: {
                screen: "DetailDinning",
              },
            },
          },
        },
      },
      {
        id: "Market",
        name: "Market",
        icon: "/assets/images/icons/dining-tabs/market.png",
        action: {
          screen: "/Dining/Restaurants/Market",
          params: {
            previousScreen: "Dining",
            subcategory: {
              id: "Market",
              name: "Market",
              icon: "/assets/images/icons/dining-tabs/market.png",
              action: {
                screen: "DetailDinning",
              },
            },
          },
        },
      },
      {
        id: "Desserts",
        name: "Desserts",
        icon: "/assets/images/icons/dining-tabs/dessert.png",
        action: {
          screen: "/Dining/Restaurants/Desserts",
          params: {
            previousScreen: "Dining",
            subcategory: {
              id: "Desserts",
              name: "Desserts",
              icon: "/assets/images/icons/dining-tabs/dessert.png",
              action: {
                screen: "DetailDinning",
              },
            },
          },
        },
      },
      {
        id: "CoffeeAndTea",
        name: "Coffee&Tea",
        icon: "/assets/images/icons/dining-tabs/coffee.png",
        action: {
          screen: "/Dining/Restaurants/CoffeeAndTea",
          params: {
            previousScreen: "Dining",
            subcategory: {
              id: "CoffeeAndTea",
              name: "Coffee&Tea",
              icon: "/assets/images/icons/dining-tabs/coffee.png",
              action: {
                screen: "DetailDinning",
              },
            },
          },
        },
      },
    ],
  },
};
